<template>
  <div class="page">
    <aside class="first-half">
      <div>
        <figure class="logo">
          <img src="@/assets/img/logo.svg" alt="Logo"/>
        </figure>
        <form class="login-form">
          <h1 class="title">Login to your account</h1>

          <template v-if="showError">
            <p class="error-msg">
              {{ errorMsg }}
            </p>
          </template>

          <InputText
              label="Email"
              placeholder="Your email"
              type="email"
              class="mb-6"
              name="email"
              v-model="userCredentials.email"
          />
          <InputText
              label="Password"
              placeholder="Your password"
              type="password"
              name="password"
              v-model="userCredentials.password"
          />
          <Checkbox label="Stay logged in"/>

          <div class="block ml-auto py-2 w-32" v-if="isLoading">
            <Loading margin="my-0 mr-0" label="Logging in" :show-label="true"/>
          </div>

          <ButtonPrimary
              v-else
              type="submit"
              text="Login"
              @click.native.prevent="submitLogin"
              class="btn-login"
          />
        </form>
        <footer>
          <div class="footer-container">
            <span class="credits">
              Made with ♥ remotly from Ghana, Nigeria, Mauritius & Switzerland.
            </span>
            <span class="credits copyrights">
              © {{ new Date().getFullYear() }} Travel Afrique, LLC. All rights
              reserved.
            </span>
          </div>
        </footer>
      </div>
    </aside>
    <aside class="second-half">
      <img
          class="bg-img"
          :src="require(`@/assets/img/backgrounds/${imgPath}`)"
          alt="Background Image"
      />
    </aside>
  </div>
</template>
<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import Checkbox from "@/components/Inputs/Checkbox";
import InputText from "@/components/Inputs/InputText";
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Preloaders/Loading";

export default {
  name: "Login",
  components: {
    Loading,
    ButtonPrimary,
    Checkbox,
    InputText,
  },
  data() {
    return {
      imgPath: "1.jpeg",
      isLoading: false,
      showError: false,
      errorMsg: "",
      userCredentials: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    async submitLogin() {
      this.isLoading = true;

      this.login(this.userCredentials)
          .then(() => {
            this.isLoading = false;
            this.$router.push(this.$route.query.redirect || {name: "Home"});
          })
          .catch((e) => {
            this.showError = true;
            this.errorMsg = e.response.data.message;

            this.isLoading = false;

            //hide the error after a minute

            setTimeout(() => {
              this.showError = false;
            }, 10000);
          });
    },

    getRandomImg(totalImg) {
      let random = Math.floor(Math.random() * totalImg);
      this.imgPath = random + ".jpeg";
    },
  },

  computed: {
    ...mapGetters({
      token: "auth/token",
    }),
  },
  beforeMount() {
    this.getRandomImg(45);
  },
};
</script>
<style scoped>
.page {
  @apply w-100vw h-100vh flex sm:flex-wrap bg-grey-lighter;
}

.first-half {
  @apply sm:h-full w-full md:w-5/8 px-4 py-8 sm:p-8;
  @apply flex justify-center items-center;
}

.second-half {
  @apply hidden md:block h-full w-3/8 p-8 ml-auto relative;
}

.bg-img {
  @apply h-full w-full object-cover absolute top-0 left-0;
}

.logo {
  @apply h-13 relative my-9;
}

.logo img {
  @apply absolute object-cover sm:left-0 top-0 h-full left-1/2 -translate-x-1/2 transform sm:transform-none sm:translate-x-0;
  @apply relative left-1/2 transform -translate-x-1/2;
}

.login-form {
  @apply relative left-1/2 transform -translate-x-1/2 bg-white;
  @apply w-full max-w-sm sm:max-w-lg px-8 py-12 sm:px-16 sm:py-16;
  @apply shadow-md border border-grey-base rounded-md mb-4;
}

.title {
  @apply font-sans text-black-base font-semibold text-xl sm:text-2xl mb-5;
}

.btn-login {
  @apply px-8 block ml-auto mt-6 w-full;
}

.error-msg {
  @apply font-sans text-red-base text-sm mb-5;
}

footer {
  @apply relative left-1/2 transform -translate-x-1/2;
  @apply max-w-sm sm:max-w-lg pb-12;
}

.footer-container {
  @apply flex justify-between flex-wrap;
}

.credits {
  @apply text-black-lighter text-xs w-full md:text-sm mb-2;
}

.copyrights {
  @apply text-left;
}
</style>